.bg {
  padding: 30px 5px;
}

.bg .col {
  padding: 1px;
}

.imgContainer {
  background-color: #48ae9e !important;
  border-radius: 15px;
  border: 6px solid #48ae9e;
}

.imgContainer img {
  width: 100%;
  border-radius: 15px;
  height: 400px;
  object-fit: fill;
}

.imgContainer1 {
  /* margin-top: 20px; */
}

.ciImgContainer {
  position: relative;
  z-index: 1 !important;
  background-color: #fff;
  border-radius: 30px;
}

.ciIMG {
  width: 70%;
  height: auto !important;
  margin: auto;
  display: block;
}

.headingTwoContainer {
  padding-top: 30px;
}

.imgContainer2,
.imgContainer3 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.imgContainer3 {
  justify-content: flex-start;
}

.imgContainer2 h1,
.imgContainer3 h1 {
  margin: 0;
  padding: 2px;
  border-radius: 20px;
  border: 5px solid #48ae9e;
  margin-left: -40px;
}

.imgContainer2 h1 {
  margin-right: -40px;
  padding-right: 40px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.imgContainer3 h1 {
  margin-left: -40px;
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.headingLang h1 {
  margin-left: -40px;
  padding-left: 40px;
  padding-bottom: 10px;
}

.headingLang2 h1 {
  margin-right: -40px;
  padding-right: 40px;
  padding-bottom: 10px;
}

.col2 {
  display: flex;
}

@media screen and (max-width: 768px) {
  .col2 {
    display: block;
  }

  .imgContainer2,
  .imgContainer3 {
    align-items: center !important;
    justify-content: center !important;
  }

  .imgContainer3 {
    justify-content: center !important;
  }

  .imgContainer2 h1,
  .imgContainer3 h1 {
    margin: 0;
    padding: 2px;
    margin-left: 0px;
  }

  .imgContainer2 h1 {
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .imgContainer3 h1 {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
