.speak {
  padding: 70px 0px;
}

.speak h1 {
  width: 70%;
  margin: auto;
  text-align: center;
  color: #48ae9e;
}
