.clients {
  padding-top: 40px;
}

.clients img {
  width: 100%;
  height: 95vh;
}

@media screen and (max-width: 768px) {
  .clients img {
    height: auto;
  }
}
