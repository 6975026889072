.galleryContainer h2 {
  text-align: center;
  color: #48ae9e;
  margin: 0px 0px 30px;
}

.allIMGS {
  border: 7px solid #48ae9e;
  padding: 15px 0px;
}

.containerIMG img {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}
