.bg {
  padding: 30px 0px 0px;
}

.header2 {
  width: 90%;
  margin: auto;
  padding: 30px 20px 70px;
}

.aboutcontent {
  border: 3px solid #48ae9e;
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  margin: auto;
  margin-bottom: 80px;
}

.aboutcontent p {
  color: #444;
  font-weight: bold;
  font-size: 22px;
  color: #48ae9e;
  text-shadow: 5px 5px 9px #ddd;
  margin-bottom: 30px;
}

.imgContainer img {
  margin-bottom: 10px;
  padding: 10px;
}

.imgContainer img,
.imgContainer2 img {
  width: 100%;
  border-radius: 10px;
  border: 3px solid #48ae9e;
}

.imgContainer2 img {
  height: 250px;
  padding: 5px;
}

.servIMG1 {
  padding-top: 50px;
  border-right: 3px solid #48ae9e;
  border-bottom: 3px solid #48ae9e;
  padding-bottom: 10px;
}
.servIMG2 {
  padding-top: 50px;
  border-left: 3px solid #48ae9e;
  border-bottom: 3px solid #48ae9e;
}
.servIMG3 {
  border-right: 3px solid #48ae9e;
  border-top: 3px solid #48ae9e;
  padding-top: 10px;
  padding-bottom: 50px;
}
.servIMG4 {
  border-left: 3px solid #48ae9e;
  border-top: 3px solid #48ae9e;
  padding-top: 10px;
  padding-bottom: 50px;
}

.featuresContent2 {
  padding: 42px 30px;
}

.featuresContent h3,
.advantagesContent h3,
.featuresContent2 h3 {
  color: #48ae9e;
  text-shadow: 5px 5px 9px #ddd;
  font-weight: bold;
}

.advantagesContent h6 {
  text-shadow: 5px 5px 9px #ddd;
  font-size: 21px;
  margin-bottom: 15px;
}

.featuresContent h3,
.featuresContent2 h3 {
  display: inline-block;
  border-bottom: 1px solid #48ae9e;
  margin-bottom: 20px;
  font-size: 30px;
}

.featuresContent p,
.featuresContent2 p {
  color: #444;
  font-weight: 600;
  font-size: 28px;
  color: #48ae9e;
  text-shadow: 5px 5px 9px #ddd;
  margin-bottom: 20px;
}

.ulcontainer ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.ulcontainer ul li {
  font-style: italic;
  font-size: 19px;
  font-weight: 500;
}

.testP {
  margin-top: 30px;
  width: 100%;
  font-weight: 500;
  color: #50b4a4;
  font-size: 22px;
  text-shadow: 5px 5px 9px #ddd;
}

.headerTwo {
  margin-top: 70px;
  border-top: 4px solid #48ae9e;
  /* padding-top: 20px; */
  /* padding: 0px 20px;  */
  width: 90%;
  margin: auto;
}

.headerTwo .imgContainer img {
  height: 500px;
}

/* headerthree */
.headerthree {
  background: #48ae9e;
  padding: 20px;
}

.containerAbout {
  background: #48ae9e;
  padding: 20px;
}

.aboutContianer {
  color: #fff;
  font-weight: bold;
  padding: 20px;
}

.aboutContianer h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.aboutContianer h4 {
  font-size: 28px;
  margin-bottom: 50px;
}

.aboutUl {
  margin: 0;
  padding: 0;
  margin: auto;
}

.aboutUl li {
  font-size: 22px;
  color: #fcfcfc;
}

.imgContainer3 {
  margin-top: 10px;
}

.imgContainer3 img {
  border-radius: 10px;
  border: 2px solid #fff;
  width: 100%;
  height: 250px;
}

/* headerThree */

@media screen and (max-width: 768px) {
  .bg {
    padding: 0px;
    margin-top: 0;
  }

  .header {
    border-radius: 0px;
    padding: 0px;
    margin-top: 90px;
  }

  .aboutcontent p {
    width: 95%;
    padding: 20px 0px;
    font-size: 19px;
    margin-bottom: 20px;
  }

  .imgContainer {
    margin-bottom: 40px;
  }
}

/* EDIT */
.arImg1 {
  padding-top: 50px;
  border-left: 3px solid #48ae9e;
  border-bottom: 3px solid #48ae9e;
  padding-bottom: 10px;
}

.arImg2 {
  padding-top: 50px;
  border-right: 3px solid #48ae9e;
  border-bottom: 3px solid #48ae9e;
}

.arImg3 {
  border-left: 3px solid #48ae9e;
  border-top: 3px solid #48ae9e;
  padding-top: 10px;
  padding-bottom: 50px;
}

.arImg4 {
  border-right: 3px solid #48ae9e;
  border-top: 3px solid #48ae9e;
  padding-top: 10px;
  padding-bottom: 50px;
}

/* ******************Contact US******************* */
.contactContainer,
.contactContainer1 {
  padding: 30px 20px;
  border: 7px solid #48ae9e;
  margin: 30px 10px;
  border-radius: 10px;
}

.contactContainer1 .iconContainer img {
  width: 100%;
  height: 100px;
}

.iconContainer {
  /* margin-right: 20px; */
}

.iconContainer img {
  height: 120px;
}

.formGroup,
.formGroupMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.formGroupMessage {
  align-items: stretch;
}

.formGroup label,
.formGroupMessage label {
  /* margin-right: 15px; */
  font-weight: bold;
  color: #48ae9e;
  font-size: 19px;
}

.formGroup input,
.formGroupMessage textarea {
  background: #fff !important;
  width: 310px;
  border-color: #48ae9e;
  border-radius: 30px;
  resize: none;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.btnContainerAR {
  justify-content: flex-start;
}

.btnSend,
.btnSend1 {
  outline: none;
  border: none;
  padding: 0px 25px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 600;
  color: #444;
  transition: 0.3s;
  border: 3px solid #48ae9e !important;
  background: #f2f2f2;
  color: #48ae9e;
}

.borderContainer {
  padding: 0px 40px;
}

.details {
  border-left: 6px solid #48ae9e;
}

.details1 {
  border-right: 6px solid #48ae9e;
}

.firstDetails {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  margin: auto;
}

.iconContainer {
  margin-right: 20px;
}

.iconContainerAR {
  margin-left: 20px;
}

.iconContainerAR img {
  width: 100%;
  height: 120px;
}

.content h6 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #fff;
  margin-right: 20px;
}

.content p {
  margin: 0;
  padding: 0;
  color: #48ae9e;
  text-shadow: 7px 7px 4px rgba(129, 129, 129, 0.552);
  font-size: 20px;
  letter-spacing: 0.6px;
}

.content p {
  display: flex;
  align-items: center;
}

.content p span {
  /* font-size: 17px; */
  margin-left: 7px;
  /* font-weight: bold; */
}

.content p img {
  width: 25px;
}

.content .btnSend:hover {
  background-color: #0d7e74;
  border: 1px solid #0d7e74;
}

.firstDetailsBackground {
  border-top: 6px solid #48ae9e;
}

.content2 {
  margin-left: 30px;
}

.content2 img {
  margin-left: 20px;
}

.imgMessageIcon {
  /* width: 900px !important; */
  height: 250px !important;
}

@media screen and (max-width: 769px) {
  .contactContainer1 .iconContainer img {
    width: 100%;
    height: 100px;
  }
}

@media screen and (max-width: 769px) {
  .wrapper {
    overflow: auto;
  }

  .container {
    margin-top: 70px;
    margin-bottom: 10px;
    padding: 0;
  }

  .contactContainer1 h3 {
    font-size: 33px;
    margin-bottom: 35px;
  }

  .contactContainer1 .iconContainer img {
    width: 100%;
    height: 100px;
  }

  .details {
    margin-bottom: 30px;
  }

  .btnSend,
  .btnSend1 {
    color: #000 !important;
    width: 200px;
    margin: auto;
    display: block;
  }

  .imgMessageIcon {
    /* width: 50% !important; */
    margin: auto;
    display: block;
  }

  .formGroup,
  .formGroupMessage {
    flex-direction: column;
    margin-top: 30px;
  }

  .formGroup input,
  .formGroupMessage textArea {
    width: 100%;
  }

  .btnContainer {
    justify-content: center;
  }

  .firstDetails {
    flex-direction: column;
    padding: 20px 0px;
    width: 100%;
  }

  .borderContainer {
    padding: 0px 0px;
  }
  .iconContainer {
    margin-right: 0px;
  }
}

.label {
  font-weight: bold;
  color: #48ae9e;
  font-size: 18px;
}

.inputControl {
  background: #fff !important;
  width: 310px;
  border-color: #48ae9e;
  border-radius: 30px;
}

.textarea {
  width: 100%;
  resize: none;
  border-radius: 30px;
  height: 100px;
  padding: 10px;
}
