.doors {
  margin-top: 50px;
}

.containerIMG {
  margin: 10px 0px;
  /* overflow: hidden; */
}

.containerIMG img {
  width: 100%;
  height: 250px;
  border-radius: 20px;
}

/* modal */
.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container img {
  width: 700px;
  height: 550px;
  border-radius: 30px;
}

