.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  /* direction: ltr; */
}
.container {
  /* background-color: #314059; */
  background-color: #f1f1f1;
  max-width: 850px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  border-radius: 30px;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 40px;
  right: 12px;
  transform: translateY(-100%);
  font-size: 1.7rem;
  /* padding: 0.1rem; */
  border-radius: 50%;
  /* background-color: #48ae9e; */
  border: 1px solid #48ae9e;
  /* color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease all;
  z-index: 1;
}

.closeButton:hover {
  background-color: #e32525;
  /* background-color: #ddd; */
  color: white;
}

.contactContainer,
.contactContainer1 {
  padding: 50px 20px;
  background-color: #48ae9e;
  border-radius: 30px;
  width: 100%;
}

.contactContainer1 h3 {
  margin-bottom: 60px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-shadow: 7px 7px 4px rgba(129, 129, 129, 0.552);
}

.contactContainer1 .iconContainer img {
  width: 100%;
  height: 150px;
}

.iconContainer img {
  width: 100%;
}

.formGroup,
.formGroupMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.formGroupMessage {
  align-items: stretch;
}

.formGroup label,
.formGroupMessage label {
  margin-right: 15px;
  font-weight: bold;
  color: rgb(252, 252, 252);
  font-size: 19px;
}

.formGroup input {
  background-color: #f1f1f1;
  width: 310px;
}

.formGroupMessage textarea {
  background-color: #f1f1f1;
  width: 310px;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 10px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
}

.btnSend,
.btnSend1 {
  outline: none;
  border: none;
  padding: 5px 20px;
  border-radius: 7px;
  font-size: 20px;
  font-weight: 500;
  color: #444;
  font-style: italic;
  transition: 0.3s;
  border: 1px solid #fffdd0 !important;
  color: #fffdd0;
}

.btnSend {
  background: #da9a6e;
}

.btnSend:hover {
  border: 1px solid #fff;
  color: #fff;
  background: none !important;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  margin-top: 15px;
}

.content h6 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #fff;
}

.content p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 7px 7px 4px rgba(129, 129, 129, 0.552);
  font-size: 21px;
  letter-spacing: 0.6px;
}

.content p {
  /* display: flex; */
  /* align-items: center; */
}

.content p img {
  width: 25px;
}

.content p span {
  /* font-size: 17px; */
  margin-left: 7px;
  /* font-weight: bold; */
}

.content .btnSend:hover {
  background-color: #0d7e74;
  border: 1px solid #0d7e74;
}

@media screen and (max-width: 769px) {
  .wrapper {
    overflow: auto;
  }

  .container {
    margin-top: 70px;
    margin-bottom: 10px;
    padding: 0;
  }

  .closeButton {
    color: #fff;
    border-color: #fff;
    top: 50px;
    right: 20px;
  }

  .contactContainer1{
    padding: 20px 0px;
  }

  .contactContainer1 h3 {
    font-size: 33px;
    margin-bottom: 35px;
  }

  .contactContainer1 .iconContainer img {
    width: 100%;
    height: 100px;
  }

  .details {
    margin-bottom: 30px;
  }

  .btnSend,
  .btnSend1 {
    /* color: #000 !important; */
    width: 200px;
    margin: auto;
    display: block;
  }

  .imgMessageIcon {
    width: 50% !important;
    margin: auto;
    display: block;
  }

  .formGroup,
  .formGroupMessage {
    flex-direction: column;
    margin-top: 30px;
  }

  .formGroup input,
  .formGroupMessage textArea {
    width: 100%;
  }

  .btnContainer {
    justify-content: center;
  }
}
