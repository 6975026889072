.dropdown-menu {
  background-color: #f1f1f1 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar_nav__UMzop a:first-child {
  margin-top: 0 !important;
}

.dropdown-menu a {
  color: #3ca998 !important;
}

.dropdown-menu a:hover {
  color: #fff !important;
}

.navbar_navDropDownItem__F3TwM .dropdown-item {
  margin: 0 !important;
}

.fi-gb,
.fi-sa {
  font-size: 22px;
  margin-top: 2px;
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
    background-color: #82c8bd !important;
    border: none !important;
  }

  .dropdown-menu a {
    background-color: #3ca998;
    color: #fff !important;
  }

  .navbar_nav__ikJQq a:first-child {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

  #basic-nav-dropdown {
    margin: 0 !important;
    margin-bottom: 30px !important;
  }
}
