.containerAbout {
  background: #48ae9e;
  padding: 20px;
}

.aboutContianer {
  color: #fff;
  font-weight: bold;
  width: 70%;
  margin: auto;
}

.aboutContianer h1 {
  font-size: 30px;
  margin-bottom: 15px;
}

.aboutUl {
  margin: 0;
  padding: 0;
  margin: auto;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .aboutContianer {
    color: #fff;
    font-weight: bold;
    width: 100%
  }

  .aboutContianer {
    /* padding: 150px 0px; */
  }

  .aboutContianer p {
    width: 100%;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
  }
}
/* ********************************media********************************** */
