.galleryContainer {
  padding: 30px 0px;
  border: 6px solid #48ae9e;
  margin: 0px 20px;
  border-radius: 0px 0px 40px 40px;
  margin-bottom: 50px;
}

.galleryContainer h2 {
  text-align: center;
  color: #48ae9e;
  margin: 0px 0px 30px;
  font-weight: bold;
  text-shadow: 5px 5px 5px #ddd;
}

.containerIMG h2 {
  text-align: center;
  color: #48ae9e;
  margin: 0px 0px 30px;
  font-weight: 600;
  text-shadow: 5px 5px 5px #ddd;
  font-size: 30px;
}

.containerIMG img {
  width: 100%;
  border-radius: 29px;
  cursor: pointer;
  border: 10px solid #48ae9e;
  opacity: 0.5;
}

/* allImgs */
.allImgs {
  margin: 50px 0px;
  border-top: 6px solid #48ae9e;
}

.active {
  opacity: 1 !important;
}
